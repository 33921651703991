@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: inter;
}



/* URW Geometric */
@font-face {
  font-family: 'URW Geometric';
  src: url('./fonts/URW Geometric/100.otf') format('opentype');
  font-weight: 100;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('./fonts/URW Geometric/200.otf') format('opentype');
  font-weight: 200;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('./fonts/URW Geometric/300.otf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('./fonts/URW Geometric/400.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('./fonts/URW Geometric/500.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('./fonts/URW Geometric/600.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('./fonts/URW Geometric/700.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('./fonts/URW Geometric/800.otf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('./fonts/URW Geometric/900.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'Komika Axis';
  src: url('./fonts/Komika Axis/400.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/100.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/200.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/300.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/400.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/500.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/600.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/700.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/800.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/900.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Arvo';
  src: url('./fonts/Arvo/400.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Arvo';
  src: url('./fonts/Arvo/700.ttf') format('truetype');
  font-weight: 700;
}


@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy/100.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy/200.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy/300.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy/400.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy/500.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy/600.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy/700.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy/800.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy/900.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Bebas';
  src: url('./fonts/Bebas/400.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Bazinga';
  src: url('./fonts/Bazinga/400.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Lemon Milk';
  src: url('./fonts/Lemon Milk/300.otf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'Lemon Milk';
  src: url('./fonts/Lemon Milk/400.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Lemon Milk';
  src: url('./fonts/Lemon Milk/500.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'Lemon Milk';
  src: url('./fonts/Lemon Milk/700.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Manga Temple';
  src: url('./fonts/Manga Temple/400.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Manga Temple';
  src: url('./fonts/Manga Temple/600.ttf') format('truetype');
  font-weight: 600;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

html { scroll-behavior: smooth !important; }


@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

#root {
  height: 100%;
}


video[muted="true"] {
  filter: grayscale(1);
}